<template>
  <div class="flex v-center" style="gap: 16px" v-if="!$store.state.loadingUser">
    <div class="user-dropdown">
      <b-dropdown variant="link" :right="!!loggedInUser" style="font-size: 0.8rem" :toggle-class="{'plain': !loggedInUser, 'logged-in': !!loggedInUser}"
        no-caret block ref="dropdown" toggle-tag="div" boundary="window">
        <!-- Content for badge that expands details -->
        <template slot="button-content">
          <template v-if="loggedInUser">
            <user-badge interactive :notification-count="notificationCount"/>
          </template>
          <template v-else>
            <div class="flex v-center">
              <b-icon icon="lock-fill" font-scale="0.9" style="margin-right: .5em; margin-top: -3px"></b-icon>
              <span style="font-size: 0.9em; white-space: nowrap">Sign In</span>
            </div>
          </template>
        </template>
        <!-- Content for body of dropdown -->
        <div class="top-body">
          <div v-if="loggedInUser" class="body-list">
            <div style="position: relative">
              <Transition name="dissolve-out">
                <div key="main" class="main" v-if="!navOverlayVisible">
                  <div class="header">My Stuff</div>
                  <div class="flex link-toggles" style="justify-content: space-evenly">
                    <!-- Quick nav dropdowns -->
                    <b-button variant="dark" v-for="navOpt in userNavOptions" :key="navOpt.name" @click.stop.prevent="showNavOverlay(navOpt)" :disabled="!navOpt.unlocked">
                      <div>
                        <div class="flex-col center">
                          <div class="icon"><icon-set :icon="navOpt.icon" width="1em" height="1em"></icon-set></div>
                          <div class="label">{{navOpt.label || navOpt.name}}</div>
                        </div>
                      </div>
                    </b-button>
                  </div>
                </div>
              </Transition>
              <Transition name="dissolvey" appear>
                <div key="nav-overlay" v-if="navOverlayVisible" :class="['nav-overlay', { visible: navOverlayVisible }]">
                  <div class="header v-h-center">
                    <b-button variant="link" @click.stop.prevent="hideNavOverlay"><b-icon icon="chevron-left" /></b-button>
                    <div class="flex center" style="flex-grow: 1;">
                      <div class="icon"><icon-set :icon="selectedNavOpt.icon" width="1em" height="1em"></icon-set></div>
                      <div>{{selectedNavOpt.name}}</div>
                    </div>
                  </div>
                  <div class="links" @click="$refs.dropdown.hide()">
                    <router-link :to="{name: childRoute.name || childRoute.defaultName}" class="link dropdown-item" v-for="childRoute in selectedNavOpt.children" :key="childRoute.name">{{childRoute.label || childRoute.name}}</router-link>
                  </div>
                </div>
              </Transition>
            </div>
            <hr />
            <b-button block v-if="needsToFinishAccount" class="plain notification" variant="dark" @click="showAccountModal = true">
              <b-icon icon="exclamation-circle" style="margin-right: 0.5rem" variant="warning" scale="0.8" />
              <span style="font-size: 0.8rem">Finish customizing your profile</span>
            </b-button>
            <hr v-if="needsToFinishAccount" />
            <b-button block v-if="!loggedInUser.verified" class="plain notification" variant="dark" @click="showAccountModal = true">
              <b-icon icon="exclamation-circle" style="margin-right: 0.5rem" variant="warning" scale="0.8" />
              <span style="font-size: 0.8rem">Verify your email</span>
            </b-button>
            <hr v-if="!loggedInUser.verified" />
            <div class="flex footer" style="width: 100%">
              <div>
                <b-button class="plain" variant="dark" size="sm" ref="profileBtn" @click.stop="showInfoModal">
                  <span class="icon"><b-icon icon="person-circle" /></span>
                  <span>Account Settings</span>
                </b-button>
              </div>
              <div id="lougoutBtn">
                <b-button class="plain" variant="dark" @click="logout" size="sm" style="font-size: 0.8em">
                  <span class="icon"><b-icon icon="box-arrow-left" /></span>
                  <span>Sign out</span>
                </b-button>
              </div>
            </div>
          </div>
          <!-- If not logged in, show inline login form in dropdown body -->
          <!-- had to switch to v-show to get login event to work -->
          <div v-show="!loggedInUser" class="text-center login">
            <login-form @login="loggedIn" show-signup-link @signup="showSignupModal = true"></login-form>
          </div>
        </div>
      </b-dropdown>
    </div>
    <b-button v-if="!loggedInUser" @click="showSignupModal = true" variant="primary" style="font-size: 0.9rem; padding-left: 24px; padding-right: 24px; white-space: nowrap">Reserve Account</b-button>
    <div class="user-info" v-if="loggedInUser">
      <b-modal id="user-modal" modal-class="user-modal" ref="user-info-modal" hide-footer ok-only footer-border-variant="none" @hidden="cancelCustomization">
        <template slot="modal-header">
          <b-button-close @click="$refs['user-info-modal'].hide()" variant="light" style="position: absolute; right: 16px; top: 16px; cursor: pointer; z-index: 1100"></b-button-close>
        </template>
        <div class="flex-col center" style="padding: 32px; width: 100%">
          <user-badge :editable="showCustomizationOptions" ref="badge" :color-override="customColor" :image-override="customProfilePic" @displayNameUpdated="customDisplayName = $event" />
          <div style="margin-top: 12px">
            <b-button variant="secondary" size="sm" @click="showCustomizationOptions = !showCustomizationOptions" v-if="!showCustomizationOptions">
              <b-icon icon="palette" scale="0.8" />&nbsp;
              Customize
            </b-button>
          </div>
          <div v-if="showCustomizationOptions" style="margin-top: 8px">
            <user-theming @profilePicChanged="customProfilePic = $event" @colorChanged="customColor = $event"></user-theming>
            <div class="flex center" style="column-gap: 8px; margin-top: 8px">
              <loading-button :async-func="saveCustomization" size="sm" variant="primary" :disabled="!(customProfilePic !== undefined && customProfilePic !== userProfileImage || customColor && customColor !== userColor || customDisplayName && customDisplayName !== loggedInUser.display_name) || customDisplayName === null">Save Changes</loading-button>
              <b-button size="sm" @click="cancelCustomization">Cancel</b-button>
            </div>
          </div>
          <div class="details">
            <div class="grid">
              <div class="label">email</div>
              <div class="value">{{loggedInUser.email}}</div>
              <div class="label">roles</div>
              <div class="value roles">
                <pill v-for="role in userRoles" :text="role.name" :key="role.id"></pill>
              </div>
              <div class="label">verified</div>
              <div class="value">
                <b-icon :icon="loggedInUser.verified ? 'check2' : 'x'"></b-icon>
                <resend-verification-link>Resend email</resend-verification-link>
              </div>
              <div class="label">account created</div>
              <div class="value">{{loggedInUser.metadata.createdAt | date}}</div>
            </div>
          </div>
        </div>
      </b-modal>
    </div>
    <login-modal v-model="showSignupModal" signup @created="showAccountModal = true" v-if="showSignupModal"></login-modal>
    <account-modal v-model="showAccountModal" v-if="loggedInUser"></account-modal>
    <b-modal v-model="requiresNewUserPolicies" hide-footer title="Our Policies Have Been Updated!" hide-header-close no-close-on-backdrop no-close-on-esc>
      <div>
        <p style="margin-bottom: 24px;">Please review and agree below before continuing to use GAMEYE.</p>
        <user-policies v-model="acceptedNewPolicies" />
        <b-button variant="primary" style="float: right" :disabled="!acceptedNewPolicies" @click="acceptPolicies">Submit</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { BDropdown, BIcon, BModal, BButton } from 'bootstrap-vue';
import LoginForm from '@/components/LoginForm';
import LoginModal from '@/components/LoginModal';
import IconSet from '@/components/IconSet';
import UserBadge from '@/components/UserBadge';
import Pill from '@/components/Pill';
import UserTheming from '@/components/UserTheming';
import LoadingButton from '@/components/LoadingButton';
import AccountModal from '@/components/AccountCustomizationModal';
import UserPolicies from '@/components/UserPolicies';
import ResendVerificationLink from '@/components/ResendVerificationLink';
import Vue from 'vue';
import _ from 'underscore';

export default {
  name: 'UserDropdown',
  components: {
    BDropdown,
    BIcon,
    BModal,
    BButton,
    LoginForm,
    LoginModal,
    IconSet,
    UserBadge,
    Pill,
    UserTheming,
    LoadingButton,
    AccountModal,
    UserPolicies,
    ResendVerificationLink
  },
  data () {
    return {
      options: [
        {
          label: 'Logout',
          icon: 'box-arrow-left',
          click: this.logout,
          visibleLoggedIn: true
        }
      ],
      navOverlayVisible: false,
      selectedNavOpt: {},
      showSignupModal: false,
      showCustomizationOptions: false,
      customColor: undefined,
      customProfilePic: undefined,
      customDisplayName: undefined,
      showAccountModal: false,
      acceptedNewPolicies: false
    }
  },
  computed: {
    requiresNewUserPolicies () {
      return !!this.loggedInUser && !this.loggedInUser.accepted_latest_legal;
    },
    visibleOptions () {
      return _.filter(this.options, opt => {
        return !!this.loggedInUser === !!opt.visibleLoggedIn && !this.loggedInUser === !!opt.visibleLoggedOut;
      })
    },
    userRoles () {
      return this.$store.getters.getUserRoles;
    },
    availableNavOptions () {
      const routes = _.where(this.$router.options.routes[0].children, {inNav: true});
      let parentRoutes = _.filter(routes, this.checkRouteAuth);
      // now filter child routes
      return _.map(parentRoutes, route => {
        if (route.children) {
          let allowedRoutes = _.filter(route.children, childRoute => {
            return childRoute.inNav && this.checkRouteAuth(childRoute);
          });
          Vue.set(route, "children", _.map(allowedRoutes, r => {
            r.path = `/${route.path}/${r.path}`;
            return r;
          }));
        }
        return route;
      })
    },
    userNavOptions () {
      return _.filter(this.availableNavOptions, nav => {
        return nav.component.name !== "ItemsEncyclopedia";
      });
    },
    needsToFinishAccount () {
      // Note: this is very not generic
      let user = this.loggedInUser;
      return !user.display_name || !user.user_blob || !user.user_blob.color || user.user_blob.profile_pic === undefined;
    },
    notificationCount () {
      let ct = 0;
      if (this.needsToFinishAccount) ct++;
      if (!this.loggedInUser.verified) ct++;
      return ct;
    }
  },
  methods: {
    checkRouteAuth (route) {
      return !route.meta || !route.meta.requiredAuthLevel || this.checkUserLevel(route.meta.requiredAuthLevel);
    },
    logout () {
      this.$store.dispatch('signOutAction');
      this.$refs.dropdown.hide();
    },
    loggedIn () {
      this.$refs.dropdown.hide();
    },
    showInfoModal () {
      this.$refs['user-info-modal'].show();
    },
    showNavOverlay (navOpt) {
      this.selectedNavOpt = navOpt;
      this.navOverlayVisible = true;
    },
    hideNavOverlay () {
      this.selectedNavOpt = {};
      this.navOverlayVisible = false;
    },
    cancelCustomization () {
      this.customColor = undefined;
      this.customProfilePic = undefined;
      this.customDisplayName = undefined;
      this.showCustomizationOptions = false;
    },
    saveCustomization () {
      let savedChangesObj = {
        user_blob: {}
      }
      if (this.customDisplayName && this.customDisplayName !== this.loggedInUser.display_name) savedChangesObj.display_name = this.customDisplayName;
      if (this.customColor) savedChangesObj.user_blob.color = this.customColor;
      // null is valid selected state
      if (this.customProfilePic === null) savedChangesObj.user_blob.profile_image_id = null;
      else if (this.customProfilePic && this.customProfilePic.id !== this.userProfileImageId) savedChangesObj.user_blob.profile_image_id = this.customProfilePic.id;
      return this.$store.dispatch('editUserInfo', savedChangesObj).then(this.cancelCustomization);
    },
    acceptPolicies () {
      this.$store.dispatch('editUserInfo', {accepts_policies: true});
    }
  }
}
</script>

<style lang="scss" scoped>
h6, h5, h3 {
  margin-bottom: 1.5px;
}
::v-deep li {
  margin: 0 12px;
  border-top: 1px solid #484848;
  &:first-of-type {
    margin-top: 8px;
  }
}
.login {
  min-width: 150px;
  margin: 16px;
  &::v-deep input {
    font-size: 0.8rem;
    padding-right: 15px;
  }
}

.user-dropdown {
  &::v-deep > .b-dropdown {
    &.show .user-badge {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    &.show > .dropdown-toggle {
      .user-info-cell:hover {
        background-color: var(--badge-bg-color);
      }
    }
    > .btn-link.dropdown-toggle.logged-in {
      color: white;
      text-decoration: none;
      box-shadow: none;
      display: flex;
      align-items: center;
      padding: 0;
    }
    >.dropdown-menu {
      background-color: #313739;
      margin-top: 0;
      padding: 0;
    }
    .dropdown-menu, .dropdown-item {
      color: white;
      border-radius: 0;
    }
    .dropdown-item:hover {
      background-color: rgba(40, 38, 44, 0.2);
    }
    .top-body {
      .body-list > *:not(hr) {
        &:not(:first-child):not(:last-child) {
          padding-top: 12px;
        }
        &:not(:last-child) {
          padding-bottom: 12px;
        }
        &.footer {
          margin-top: 8px;
        }
      }
      hr {
        border-color: gray;
        margin: 0;
      }
      .notification {
        padding-left: 8px;
        padding-right: 8px;
        border-radius: 0;
        text-align: left;
        display: flex;
        &:hover {
          cursor: pointer;
        }
      }
      > div {
        padding: 8px;
        &:not(.login) {
          min-width: 240px;
          width: 448px;
        }
        &.login {
          min-width: 128px;
          width: 192px;
        }
        &:not(.footer) {
          overflow: hidden;
        }
      }
      .header {
        margin: -8px -8px 0;
        padding: 10px;
      }
      .main .header {
        font-size: 0.75rem;
        color: lightslategray;
        margin-bottom: 8px;
      }
      .link-toggles .btn {
        margin: 0 4px;
        font-size: 0.9rem;
        width: 96px;
        fill: white;
        box-shadow: none;
        &:not(.disabled) {
          background-color: var(--gameye-blue-faded-30);
          &:hover {
            background-color: var(--gameye-blue);
          }
        }
        &.disabled {
          color: gray;
          fill: gray;
        }
        &:after {
          margin-left: 0;
        }
      }
      .footer {
        justify-content: space-between;
        button {
          font-size: 0.8rem;
          .icon {
            text-align: center;
            margin-right: 8px;
          }
          svg {
            width: 0.8rem;
          }
        }
      }
      .nav-overlay {
        height: 100%;
        .header {
          background-color: var(--gameye-blue-faded-30);
          border-bottom: 1px solid var(--gameye-blue);
          .icon {
            margin-right: 8px;
            fill: white;
            margin-top: -3px;
          }
        }
        .links {
          margin: 8px 16px 0;
          .dropdown-item {
            padding: 4px 16px;
            &:not(:last-child) {
              border-bottom: 1px solid rgb(72, 72, 72);
            }
          }
        }
        .btn-link {
          padding-top: 0;
          padding-bottom: 0;
          color: white;
          position: absolute;
        }
      }
      .dissolve-out-leave-active,
      .dissolvey-leave-active {
        position: absolute;
        top: 8px;
        left: 0;
      }
      .dissolvey-leave-active {
        width: 100%;
      }
    }
  }
}

::v-deep .user-modal {
  font-size: 14px;
  .modal-header {
    padding: 0;
    border-bottom: none;
    .close {
      text-shadow: none;
      cursor: pointer;
    }
  }
  .main-info {
    .handle {
      margin-bottom: 3px;
    }
  }
  .details {
    background-color: var(--list-item-footer-bg);
    margin-top: 32px;
    padding: 16px;
    width: 100%;
    .grid {
      grid-template-columns: 1fr 1fr;
      row-gap: 1px;
      background-color: #484848;
      > div {
        background-color: var(--list-item-footer-bg);
        padding: 8px 0;
        &.value {
          text-align: right;
        }
        &.label {
          color: #9f9f9f;
        }
      }
    }
  }
}
</style>