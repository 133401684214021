<template>
  <component :is="isNav ? 'b-pagination-nav' : 'b-pagination'" class="pagination-w"
    v-model="currentPage" v-bind="$attrs" v-on="listeners" ref="pagination"
    use-router :link-gen="linkGen" :number-of-pages="($attrs['total-rows'] || $attrs.totalRows)/($attrs['per-page'] || $attrs.perPage)">
    <template v-slot:page="{active}">
      <b-input type="number" v-if="active" v-model="manualPage" style="width: 2.5rem" @keyup.enter.native="applyPage" />
    </template>
  </component>
</template>

<script>
import _ from "underscore";

export default {
  name: "CustomPagination",
  props: {
    value: {
      type: [Number, String],
      required: true
    },
    isNav: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      manualPage: this.value || 1
    }
  },
  computed: {
    currentPage: {
      get () {
        return this.value;
      },
      set (val) {
        this.$emit("input", val);
      }
    },
    listeners () {
      return _.omit(this.$listeners, "input")
    }
  },
  methods: {
    linkGen (val) {
      return {
        path: this.$route.path,
        query: {... this.$route.query, ...{p: val}}
      };
    },
    manualPageUpdated (val) {
      if (this.hasValue(val) && !this.isEmpty(val)) this.manualPage = val;
    },
    applyPage () {
      if (this.manualPage < 1) this.manualPage = 1;
      if (this.manualPage >= this.$refs.pagination.localNumberOfPages) this.manualPage = this.$refs.pagination.localNumberOfPages;
      this.currentPage = this.manualPage;
    }
  },
  watch: {
    currentPage (newVal) {
      this.manualPage = newVal;
    }
  }
}
</script>

<style lang="scss" scoped>
.pagination-w {
  margin-bottom: 0;
  :deep(.page-item) {
    &:not(.disabled) > .page-link {
      color: var(--link-text);
    }
    > .page-link {
      background-color: transparent;
      border-color: rgb(56, 61, 63);
      height: 100%;
      display: flex;
      align-items: center;
    }
    &:not(.active) > .page-link {
      &:hover {
        background-color: #282e31;
      }
    }
    &.active > .page-link {
      background-color: var(--gameye-blue);
      color: white;
      padding: 0.35rem 0.25rem;
      &:hover {
        cursor: default;
      }
      input {
        &::-webkit-outer-spin-button,
        &::-webkit-inner-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
        -moz-appearance: textfield;
        padding: 0;
        line-height: 1.5rem;
        background-color: transparent;
        &:hover, &:focus, &:active, &:focus-visible {
          background-color: #ffffff33;
        }
        outline: none;
        border: none;
        border-radius: 0 !important;
        height: unset;
        text-align: center;
        color: white;
        font-size: 1em;
      }
    }
    &:not(.active), &.disabled {
      .page-link {
        background-color: transparent;
        border: 1px solid #525252;
      }
    }
  }
}
</style>
