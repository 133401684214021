export default {
  props: {
    store: { // store to get/store data from
      type: String,
      required: true
    },
    category: { type: String, default: "all" }, // (can be) from route
    query: { type: String }, // (can be) from route
    sort: { type: String }, // (can be) from route
    filters: { type: [String, Array] }, // (can be) from route
    page: { type: Number, default: 1 }, // (can be) from route
    pageSize: { type: Number, default: 25 } // (can be) from route
  },
  computed: {
    itemsList () {
      return this.$store.state[this.store].items;
    }
  }
}