import _ from 'underscore';

export default {
  props: {
    item: {
      type: Object,
      required: true
    }
  },
  data () {
    return {
      itemGenres: [],
      itemDevs: [],
      itemPubs: [],
      selected: false,
      registered: false
    }
  },
  created () {
    if(this.$parent.registerItem) {
      this.$parent.registerItem(this);
      this.registered = true;
    }
    this.itemGenres = this.parseObjectKeysForMatchingIds(this.genres, this.item.genres);
    this.itemDevs = this.parseObjectKeysForMatchingIds(this.developers, this.item.devs);
    this.itemPubs = this.parseObjectKeysForMatchingIds(this.publishers, this.item.pubs);
  },
  computed: {
    images () {
      return _.values(_.filter(this.item.FrontEndGameImages, function (val) { return val !== null && val !== ''; }))
    }
  },
  methods: {
    clicked () {
      this.selected = !this.selected;
      this.$emit('selected', this.item, this.selected);
      if (this.registered) this.$parent.selectItem(this, this.selected);
    }
  }
};
