<template>
  <div class="details-container">
    <div class="back-link">
      <a @click="goBack"><b-icon-arrow-left /> Back to Browse</a>
    </div>
    <game-details :id="itemId"></game-details>
  </div>
</template>

<script>
import GameDetails from "@/components/GameDetails";
import {BIconArrowLeft} from "bootstrap-vue";

export default {
  name: 'ItemDetails',
  components: {
    GameDetails,
    BIconArrowLeft
  },
  data () {
    return {
      fromEncyclopedia: false
    }
  },
  computed: {
    itemId () {
      return this.$route.params.id
    }
  },
  beforeRouteEnter (to, from, next) {
    next(vm => {
      vm.fromEncyclopedia = from.name === "Browse" || from.name === "Pricification";
    });
  },
  methods: {
    goBack () {
      if (this.fromEncyclopedia) this.$router.go(-1);
      else this.$router.push({ name: 'Browse' });
    }
  }
}
</script>

<style scoped>
.back-link {
  padding: 4px 0 12px 0;
  position: absolute;
  margin: 10px 12px 20px;
  z-index: 100;
}

.back-link > a:hover {
  cursor: pointer;
}
</style>