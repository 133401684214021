<template>
  <b-overlay :show="isLoading" rounded="sm" variant="dark">
    <div :class="['item flex-col', {
        accepted: completed && accepted,
        declined: completed && !accepted,
        adding: queueTypeId === queueTypeIds.NEW,
        editing: queueTypeId === queueTypeIds.EDIT,
        deleting: queueTypeId === queueTypeIds.DELETE,
        overriding: mergeOverride
      }]">
      <template v-if="!completed">
        <div class="flex v-center" style="justify-content: space-between">
          <div class="flex v-center">
            <a target="_blank" style="margin-right: 12px;" :href="sourceLink">{{queueType}} #{{queueId}}&nbsp;<b-icon icon="box-arrow-up-right" scale="0.8" shift-v="2" /></a>
            <div class="flex v-center grey" style="font-size: 0.9em">Added: {{item.created_at | date-local}}</div>
            <div v-if="item.created_by" class="flex v-center grey">
              &nbsp;by user {{item.created_by}}
            </div>
          </div>
          <b-badge v-if="itemType" :variant="typeBadgeVariant" class="flex v-center" style="font-weight: normal; font-size: 1.1rem">
            <icon-set style="margin-top: -2px" class="icon unfilled" :icon="itemType.icon" width="14px" height="14px"></icon-set>
            &nbsp;&nbsp;{{itemType.label}}
          </b-badge>
        </div>
        <div class="content flex-col">
          <template v-if="!item.error">
            <template v-if="isEdit && oldData || queueTypeId === queueTypeIds.DELETE">
              <!-- edit or removing existing item -->
              <div class="header flex-col" style="row-gap: 8px">
                <div class="flex v-center" style="column-gap: 16px">
                  <h4 style="margin: 0">{{item.title}}</h4>
                  <flag-icon :country="metadata.countries[oldData.country_id]"></flag-icon>
                  <div class="flex v-center">
                    <icon-set icon="logo" height="16px" />&nbsp;
                    <a :href="detailsLink" target="_blank">
                      #{{itemId}}
                    </a>
                  </div>
                </div>
                <div class="flex v-center" style="gap: 8px">
                  <platform :platform-id="oldData.platform_id"></platform>
                </div>
                <div v-if="mergeOverride">
                  <a @click.prevent="cancelSetMerge" href style="font-size: 0.9em; white-space: nowrap"><b-icon icon="x-lg" />&nbsp;&nbsp;Cancel Merge</a>
                </div>
              </div>
              <template v-if="isEdit && oldData">
                <div class="data flex-col" v-if="!isEmpty(item.data)">
                  <!-- IF there's data -> if there's not, this is a blank change (should be images only) -->
                  <div class="values grid" v-if="!isEmpty(editedFields)">
                    <div style="grid-column-start: 3; margin-right: 35px">
                      <icon-set icon="logo" />
                    </div>
                    <div class="value" style="grid-column-start: 5; background-color: transparent; margin-left: 15px">
                      {{queueType}}
                    </div>
                  </div>
                  <div v-for="f in editedFields" :key="f">
                    <div :class="['values grid', {selected: updateMode && selectedFields[f]}]">
                      <div><b-form-checkbox v-if="updateMode" v-model="selectedFields[f]" /></div>
                      <div><div class="italic">{{f}}</div></div>
                      <div>
                        <div class="value">
                          <item-field-display :value="oldData[f]" :field-name="f" :displayAs="overrideFieldDisplay"></item-field-display>
                        </div>
                      </div>
                      <b-icon style="margin: 4px 8px" icon="arrow-right" />
                      <div>
                        <div :class="['value', getValueClass(f)]">
                          <item-field-display :value="item.data[f]" :field-name="f" :displayAs="overrideFieldDisplay"></item-field-display>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="flex center v-center">
                  <h3 style="color: var(--danger)"><b-icon icon="trash-fill" scale="0.8"/>Delete Item</h3>
                </div>
              </template>
            </template>
            <template v-else-if="queueTypeId === queueTypeIds.NEW">
              <!-- Adding new entry -->
              <div class="header flex-col v-center" style="column-gap: 6px">
                <div class="flex v-center" style="column-gap: 12px">
                  <h6 class="bold margin-0" style="color: var(--success)">New!</h6>
                  <h4 class="margin-0">{{item.title}}</h4>
                </div>
                <div class="flex v-center" style="gap: 12px" v-if="canMergeWithExisting">
                  <a @click.prevent="showMergeOpt = !showMergeOpt" href style="font-size: 0.9em; white-space: nowrap"><b-icon icon="alt" flip-h />&nbsp;&nbsp;Merge w/ Existing</a>
                  <b-form v-if="showMergeOpt" inline @submit.prevent="saveSetMerge">
                    <b-form-input v-model="mergeWithId" class="mr-1" size="sm" placeholder="GAMEYE ID" style="width: 8rem" />
                    <b-button type="submit" variant="primary" size="sm">Submit</b-button>
                    <span class="error">{{mergeWithError}}</span>
                  </b-form>
                </div>
              </div>
              <div class="data flex-col">
                <div class="grid gap-4" style="grid-template-columns: 1fr 1fr">
                  <template v-for="f in allNonSkippedFieldKeys">
                    <div :class="['values grid', {selected: updateMode && selectedFields[f]}]" style="grid-template-columns: auto 1fr 2fr" :key="f">
                      <div><b-form-checkbox v-if="updateMode" :disabled="ITEM_FIELDS_MAP[itemType.name][f] && ITEM_FIELDS_MAP[itemType.name][f].required" v-model="selectedFields[f]" /></div>
                      <div><div class="italic">{{f}}</div></div>
                      <div>
                        <div class="value">
                          <item-field-display :value="item.data[f]" :field-name="f"></item-field-display>
                        </div>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </template>
            <div class="flex" style="gap: 16px" v-if="images">
              <div class="flex-col center" style="padding: 30px 0">
                <template v-if="oldData">
                  <div class="v-h-center" style="height: 160px"><icon-set icon="logo" /></div>
                  <div style="white-space: nowrap" class="mt-1 mb-1">
                    <b-icon icon="arrow-down-short" scale="1.5" />
                    <span class="grey">/</span>
                    <b-icon icon="plus" scale="1.5" />
                  </div>
                </template>
                <div class="v-h-center" style="height: 160px">{{queueType}}</div>
              </div>
              <div class="images flex">
                <image-gallery :images="images" :images-of="item.title" :selectable="updateMode"
                  @selectedChanged="imagesSelectedChanged" :previous-images="oldData ? oldData.images || {} : undefined"
                  :allow-image-overflow="false" showAttribute="resolution"></image-gallery>
              </div>
            </div>
          </template>
          <template v-else>
            <b-alert class="mt-3 mb-0" variant="danger" show>{{item.error}}</b-alert>
          </template>
          <div class="btns flex-col">
            <b-button variant="success" style="flex-grow: 1" @click="accept" :disabled="!!item.error">
              <b-icon icon="hand-thumbs-up" />&nbsp;&nbsp;{{acceptButtonText}}
            </b-button>
            <div class="flex center" style="color: #ff8c97" v-if="error">
              <b-icon icon="exclamation-triangle-fill" />&nbsp;&nbsp;{{errorMsg}}
            </div>
            <div class="grid gap-12" :style="{'grid-template-columns': `1fr${!canSelectChanges || queueTypeId === queueTypeIds.DELETE ? '' : ' 1fr'}`}">
              <b-button v-if="canSelectChanges && queueTypeId !== queueTypeIds.DELETE" variant="secondary" @click="toggleUpdateMode" :disabled="!!item.error">
                <b-icon :icon="updateMode ? 'x-circle' : 'check2-square'" scale="0.9" />&nbsp;&nbsp;{{updateButtonText}}
              </b-button>
              <b-button variant="danger" @click="decline">
                <b-icon icon="hand-thumbs-down" />&nbsp;&nbsp;{{declineButtonText}}
              </b-button>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="content flex-col">
          <!-- completed -->
          <div class="header">
            <div class="flex v-center" style="justify-content: space-between">
              <a style="color: currentColor" :href="detailsLink" target="_blank">
                <h6 style="margin: 0">{{item.title}}&nbsp;<b-icon icon="box-arrow-up-right" scale="0.8" shift-v="2" /></h6>
              </a>
              <div :class="{accepted: accepted === true, declined: accepted === false}">
                <template v-if="accepted === true">
                  <b-icon icon="hand-thumbs-up-fill" variant="light" />&nbsp;&nbsp;
                  Accepted <a target="_blank" :href="sourceLink">{{queueType}} #{{queueId}}&nbsp;<b-icon icon="box-arrow-up-right" scale="0.8" shift-v="2" /></a>
                </template>
                <template v-if="accepted === false">
                  <b-icon icon="hand-thumbs-down-fill" variant="light" />&nbsp;&nbsp;
                  Declined <a target="_blank" :href="sourceLink">{{queueType}} #{{queueId}}&nbsp;<b-icon icon="box-arrow-up-right" scale="0.8" shift-v="2" /></a>
                </template>
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
  </b-overlay>
</template>

<script>
import _ from "underscore";
import l_ from "lodash";
import FlagIcon from "@/components/FlagIcon";
import Platform from "@/components/Platform";
import ItemFieldDisplay from "@/components/ItemFieldDisplay";
import IconSet from "@/components/IconSet";
import ImageGallery from "@/components/ImageGallery";
import metadata from "@/mixins/MetadataMixin";
import {QUEUE_TYPE_IDS, ITEM_FIELDS_MAP} from "@/store/constants.js";
import Vue from "vue";

export default {
  name: "QueueItem",
  mixins: [metadata],
  components: {
    FlagIcon,
    Platform,
    ItemFieldDisplay,
    IconSet,
    ImageGallery
  },
  data () {
    return {
      QUEUE_TYPE_IDS: QUEUE_TYPE_IDS,
      ITEM_FIELDS_MAP: ITEM_FIELDS_MAP,
      editedFields: [],
      updateMode: false,
      isLoading: false,
      selectedFields: {},
      selectedImages: {},
      buttonTexts: {
        [QUEUE_TYPE_IDS[this.queueType].EDIT]: {
          accept: {
            selecting: "Accept Selected Changes",
            notSelecting: "Accept Changes"
          },
          decline: {
            selecting: "Decline All Changes",
            notSelecting: "Decline Changes"
          },
          update: {
            selecting: "Cancel Selecting",
            notSelecting: "Select Changes to Save"
          }
        },
        [QUEUE_TYPE_IDS[this.queueType].NEW]: {
          accept: {
            selecting: "Add New Item w/ Selected Fields",
            notSelecting: "Add New Item"
          },
          decline: {
            selecting: "Do Not Add",
            notSelecting: "Do Not Add"
          },
          update: {
            selecting: "Cancel Selecting",
            notSelecting: "Select Changes to Save"
          }
        },
        [QUEUE_TYPE_IDS[this.queueType].DELETE]: {
          accept: {
            notSelecting: "Delete Item"
          },
          decline: {
            notSelecting: "Do Not Delete"
          }
        }
      },
      savedId: undefined,
      completed: false,
      accepted: false,
      error: false,
      errorMsg: undefined,
      showMergeOpt: false,
      mergeWithId: undefined,
      mergeWithError: undefined,
      mergeOverride: false,
      mergeOldData: undefined
    }
  },
  props: {
    item: {
      // Expects very specific format (based on back end API format):
      //   {
      //      id (queue id), item_id (gameye id), other_id (tgdb id), type_id (game, system, etc id),
      //      data {} (represents data from tgdb),
      //      oldData {} (represents gameye data - if applicable),
      //      imagesInQueue (nested object representing queue item -what this is- for related image change) {
      //        -> NOTE: all images added to queue are grouped into one queue item, that's why this is one object
      //        data {
      //          base_url (url for all images in this),
      //          data { tgdb_id, art (object of image types mapped to list of images) }
      //        }
      //      }
      //   }
      type: Object,
      required: true
    },
    queueType: {
      // must align to type in constants.QUEUE_TYPE_IDS
      type: String,
      required: true
    },
    queueId: {
      type: Number,
      required: true
    },
    skippedFields: {
      type: Array,
      default: () => []
    },
    skippedFieldsIfSet: {
      // fields to skip when EDITING if it has a value already
      type: Array,
      default: () => []
    },
    sourceLink: {
      type: String,
      required: true
    },
    saveFunc: {
      type: Function,
      required: true
    },
    declineFunc: {
      type: Function,
      required: true
    },
    removeFromQueueFunc: {
      type: Function,
      required: true
    },
    typeId: {
      type: Number
    },
    canSelectChanges: {
      type: Boolean,
      default: true
    },
    canMergeWithExisting: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    queueTypeIds () {
      return QUEUE_TYPE_IDS[this.queueType];
    },
    itemId () {
      // this is kinda gross. there's gotta be a better way to get this..
      if (this.item.item_id) return this.item.item_id;
      else if (!_.isEmpty(this.item.imagesInQueue) && this.item.imagesInQueue.item_id) return this.item.imagesInQueue.item_id;
      else if (!_.isEmpty(this.oldData) && this.oldData.item_id) return this.oldData.item_id;
      else return this.savedId;
    },
    itemType () {
      // this is a special error case where we don't have new data (only images) or old data (must have been deleted from GAMEYE)
      if (_.isEmpty(this.item.data) && !this.oldData) return undefined;
      let catId = _.isEmpty(this.item.data) ? this.oldData.category_id : this.item.data.category_id;
      return this.parseListForMatchingVal(this.assignableCategories, {value: catId});
    },
    isEdit () {
      return this.queueTypeId === this.queueTypeIds.EDIT;
    },
    queueTypeId () {
      return this.mergeOverride ? this.queueTypeIds.EDIT : this.typeId || this.item.type_id;
    },
    typeBadgeVariant () {
      // we'll want this dynamic in the future (or specifying a color) when more than games are
      //  in the list so we can display them with different colors
      return "primary";
    },
    allNonSkippedFieldKeys () {
      if (!this.itemType) return [];
      return _.reject(_.keys(ITEM_FIELDS_MAP[this.itemType.name]), k => {
        return _.contains(this.skippedFields, k);
      })
    },
    detailsLink () {
      return this.$router.resolve({ name: 'Details', params: { id: this.itemId } }).href;
    },
    acceptButtonText () {
      return this.buttonTexts[this.queueTypeId].accept[this.updateMode ? "selecting" : "notSelecting"];
    },
    declineButtonText () {
      return this.buttonTexts[this.queueTypeId].decline[this.updateMode ? "selecting" : "notSelecting"];
    },
    updateButtonText () {
      return this.buttonTexts[this.queueTypeId].update[this.updateMode ? "selecting" : "notSelecting"];
    },
    images () {
      if (this.item.imagesInQueue)
        return _.mapObject(this.item.imagesInQueue.data?.data?.art, c => _.map(c, a => {
          return {...a, ...{url: this.item.imagesInQueue.data?.base_url + a.url}};
        }))
      else if (this.item.data?.images) return this.item.data?.images;
      else return undefined;
    },
    oldData () {
      return this.mergeOldData || this.item.oldData;
    }
  },
  methods: {
    setEditedFields () {
      // compare fields here. return array of fields that are different
      let fields = [];
      // skip keys if value already set and in skippedFieldsIfSet
      let keys = _.reject(this.allNonSkippedFieldKeys, k => {
        return this.hasValue(this.oldData[k]) && _.contains(this.skippedFieldsIfSet, k);
      })
      // loop through keys
      _.each(keys, (key) => {
        // alt_titles on GE side are contained in a "title" block like below
        // the alt_titles are are titles with type != 0
        /*
          "titles": [
                {
                    "title": "ACA NEOGEO: Puzzle Bobble",
                    "type": 0
                },
                {
                    "title": "AkeAka NeoGeo: Puzzle Bobble",
                    "type": 1
                }
            ],
        */
        if (!(this.item.data[key] === this.oldData[key] || _.isEqual(this.item.data[key], this.oldData[key]))) fields.push(key);
      })
      this.editedFields = fields;
    },
    initSelectedFields () {
      let fields;
      if (this.queueTypeId === this.queueTypeIds.EDIT) fields = this.editedFields;
      else if (this.queueTypeId === this.queueTypeIds.NEW) fields = this.allNonSkippedFieldKeys;
      this.selectedFields = {};
      _.each(fields, f => {
        Vue.set(this.selectedFields, f, true);
      })
    },
    getValueClass (field) {
      let newVal = this.item.data[field];
      let oldVal = this.oldData[field];
      if (_.isUndefined(oldVal) || _.isNull(oldVal)) return 'new';
      else if (_.isUndefined(newVal) || _.isNull(newVal)) return 'removed';
      else return 'changed';
    },
    overrideFieldDisplay (fieldObj) {
      if (fieldObj.type === "date") return "date-time";
      else return fieldObj.type;
    },
    accept () {
      this.$emit('accept');
      this.saveQueueChange();
    },
    decline () {
      this.$emit('decline');
      this.declineQueueChange();
    },
    toggleUpdateMode () {
      this.updateMode = !this.updateMode;
    },
    imagesSelectedChanged (imagesObj) {
      this.selectedImages = imagesObj;
    },
    saveQueueChange () {
      let changeObj = {}
      let fields = _.keys(_.pick(this.selectedFields, val => { return !!val; }));
      let queueItem = this.item.data;

      // TODO: look into game_specific in edit API. it's there in add, but not edit..
      let typeObj = this.parseListForMatchingVal(this.assignableCategories, {value: _.isEmpty(this.item.data) ? this.oldData.category_id : this.item.data.category_id});;
      _.each(fields, f => {
        let fieldObj = ITEM_FIELDS_MAP[typeObj.name][f];
        if (fieldObj && fieldObj.dbKey) l_.set(changeObj, fieldObj.dbKey, queueItem[f]);
        // do both to support add and edit.. the game_specific part above is just ignored in edit
        changeObj[f] = queueItem[f];
      })

      if (this.isEdit) changeObj = { item_id: this.itemId, item_edit: changeObj };
      else if (this.queueTypeId === this.queueTypeIds.DELETE) changeObj = this.itemId;
      this.isLoading = true;
      this.saveFunc(changeObj, this.queueTypeId, queueItem).then(response => {
        if (response?.item_id) {
          this.savedId = response.item_id;
        }

        let deleteAndComplete = () => {
          this.removeFromQueueFunc(this.item).then(() => {
            this.completed = true;
            this.accepted = true;
            this.isLoading = false;
          }).catch(e => {
            this.handleError(e, "There was an issue clearing the change in queue! All changes were saved, and images were cleared from queue.");
          })
        }

        if (this.item.imagesInQueue && !_.isEmpty(this.item.imagesInQueue)) {
          this.applyImageChanges(this.selectedImages).then(() => {
            // delete image queue item first because if the other way, could end up with
            //   orphaned image queue items
              this.removeFromQueueFunc(this.item.imagesInQueue).then(() => {
                deleteAndComplete();
              }).catch(e => {
                this.handleError(e, "There was an issue clearing the image in queue! Any other changes were made, though.");
              })
          }).catch(e => {
            this.handleError(e, "Couldn't save images! Any other changes were successfully applied, though.");
          })
        } else {
          deleteAndComplete();
        }
      }).catch(e => {
        this.handleError(e, "Couldn't apply the changes from the Queue. See console for details");
      })
    },
    declineQueueChange () {
      this.declineFunc(this.item).then(() => {
        this.completed = true;
        this.accepted = false;
      })
    },
    saveNewImages (selectedImages) {
      if (selectedImages && this.item.imagesInQueue && !_.isEmpty(this.item.imagesInQueue)) {
        let images = this.item.imagesInQueue.data.data.art;
        let forms = [];
        _.each(images, (imgs, type) => {
          _.each(imgs, (img, idx) => {
            if (selectedImages[type][idx]) {
              let form = new FormData();
              form.append("item_id", this.itemId);
              form.append("type_id", type);
              form.append("file_url", this.item.imagesInQueue.data.base_url + img.url);
              form.append("TGDB", true);
              forms.push(form);
            }
          })
        })
        return Promise.all(forms.map(f => this.$store.dispatch("updates/addImage", f)));
      }
      return Promise.resolve();
    },
    removeImages (selectedRemove) {
      if (selectedRemove && this.oldData && this.oldData.images) {
        let ids = [];
        _.each(this.oldData.images, (imgs, type) => {
          _.each(imgs, (img, idx) => {
            if (selectedRemove[type] && selectedRemove[type][idx]) {
              ids.push(img.ID);
            }
          })
        })
        return Promise.all(ids.map(id => this.$store.dispatch("updates/deleteImage", { image_id: id })));
      }
      return Promise.resolve();
    },
    applyImageChanges (selectedImagesObj) {
      return Promise.all([this.saveNewImages(selectedImagesObj.add), this.removeImages(selectedImagesObj.remove)]);
    },
    saveSetMerge () {
      let handleError = e => {
        console.error("Error getting that ID!", e);
        this.mergeWithError = "Invalid ID";
      }
      this.$store.dispatch('encyclopedia/getItemDetails', this.mergeWithId).then(item => {
        this.mergeWithError = undefined;
        this.mergeOldData = item;
        this.mergeOverride = true;
      }).catch(e => {
        handleError(e)
      })
    },
    cancelSetMerge () {
      this.mergeOldData = undefined;
      this.mergeWithError = undefined;
      this.showMergeOpt = false;
      this.mergeOverride = false;
    },
    handleError(error, errorMsg) {
      console.error(errorMsg, error);
      this.error =true;
      this.errorMsg = errorMsg;
      this.isLoading = false;
    }
  },
  created () {
    if (this.item.type_id === this.queueTypeIds.EDIT) {
      this.setEditedFields();
    }
    this.initSelectedFields();
  },
  watch: {
    oldData: {
      deep: true,
      handler (newVal) {
        if (newVal) this.setEditedFields();
        this.initSelectedFields();
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.item {
  position: relative;
  padding: 16px;
  background-color: #585858;
  border-radius: 4px;
  transition: all 0.5s;
  .content {
    gap: 24px;
  }
  &.adding {
    background-color: #474d49;
    border: 2px solid var(--success);
  }
  &.deleting {
    background-color: #3d3030;
    border: 2px solid var(--danger);
  }
  &.editing.overriding {
    border: 2px solid var(--success);
  }
  &.accepted {
    background-color: #127b2a;
  }
  &.declined {
    background-color: #b32937;
  }
  .data {
    row-gap: 4px;
  }
  .values {
    grid-template-columns: auto 1fr 3fr auto 3fr;
    text-align: center;
    column-gap: 2px;
    justify-content: center;
    align-items: stretch;
    padding: 4px;
    &::v-deep .platform {
      justify-content: center;
    }
    &.selected {
      background-color: var(--primary);
    }
    > div > * {
      padding: 4px 16px;
      &.value {
        width: 100%;
        background-color: rgba(55, 55, 55);
        min-height: 25px;
        &.new {
          background-color: #3b483e;
        }
        &.changed {
          background-color: #494025;
        }
        &.removed {
          background-color: #462525;
        }
      }
    }
    .custom-control {
      min-height: 1rem;
      min-width: 4rem;
    }
  }
  .btns {
    row-gap: 12px;
  }
  .images {
    max-width: 100%;
    overflow-x: auto;
    &::v-deep .type-group {
      padding: 6px;
      .img-container {
        padding: 6px;
        margin-bottom: 2px;
      }
    }
  }

  .values, .images {
    &::v-deep .custom-checkbox .custom-control-input {
      &:not(:disabled) ~ .custom-control-label::before {
        border-color: #fff;
      }
      &:disabled ~ .custom-control-label {
        &::before {
          border-color: #999;
        }
        &::after {
          background-color: #cfe0f280;
          border-radius: 0.25rem;
        }
      }
    }
  }
}
</style>
