<template>
  <async-save-modal
    :value="value"
    @input="$emit('input', $event)"
    :async-func="saveNewItem"
    title="Add a New Item to GAMEYE"
    size="xl"
    :save-disabled="!isValid"
    :scrollable="!!itemType"
    @hidden="modalHidden"
    :close-on-ok="false">
    <template v-if="value">
      <div style="position: sticky; top: 0; z-index: 20">
        <b-alert :show="itemSaved.success === false" variant="danger" dismissible>
          <div>There was an issue creating your request! Please try again. If issues persist, please reach out to the GAMEYE team.</div>
          <div>Error: {{itemSaved.msg}}</div>
        </b-alert>
        <b-alert :show="imageUploaded.success === false" variant="danger" dismissible>
          <div>There was an issue uploading the image to the new item. Go to the item <router-link :to="{ name: 'Details', params: { id: itemSaved.item?.item_id } }" target="_blank">here</router-link> to try to fix it.</div>
          <div v-if="imageUploaded.msg"><span class="bold">Error: </span>{{imageUploaded.msg}}</div>
        </b-alert>
      </div>
      <div style="position: relative" v-if="isEmpty(itemSaved) || itemSaved.success === false">
        <b-overlay :show="savingItem" variant="dark">
          <div class="grid">
            <div>Type<span class="req">*</span>:</div>
            <div class="val">
              <custom-input v-model="itemType" type="select" :select-config="{options: () => assignableCategories, value: 'value', label: 'label'}"></custom-input>
            </div>
            <div style="grid-column-start: span 2"></div>
            <div v-if="!itemType" class="flex center v-center" style="grid-column-start: span 4; margin: 48px 0">Select an item type</div>
            <template v-else>
              <template v-for="f in fields">
                <div :key="f.label || f.key">{{f.label || f.key}}<span class="req" v-if="fieldIsRequired(f)">*</span>:</div>
                <div :key="`${f.label || f.key}-val`" :class="['val', `${f.key}-val`]">
                  <custom-input v-model="newItem[f.key]" :type="f.type" :select-config="{options: f.options, value: f.value, label: f.idListField, getIconImg: f.getIconImg, getIcon: f.getIcon, noSort: f.noSort, returnValue: true}" :required="fieldIsRequired(f)" debounce="300"></custom-input>
                </div>
              </template>
              <div>Main/Front Cover Image (no cell pics!)<span class="req">*</span>:</div>
              <div class="front_cover-val" style="position: relative">
                <b-overlay :show="uploadingImage" variant="dark">
                  <custom-input :value="newItem['front_cover']" @input="set(newItem, 'front_cover', $event)" type="image" required />
                </b-overlay>
              </div>
            </template>
          </div>
        </b-overlay>
      </div>
      <div v-else class="flex center v-center">
        <span v-if="uploadingImage">Uploading image...</span>
        <div v-else class="flex v-center" style="column-gap: 1rem">
          <div class="flex-col center">
            <b-icon-check-circle class="center text-success" font-scale="4"/>
            <h4 class="center text-success">Success!</h4>
            <div style="align-self: end">Check out/update your item here <b-icon-arrow-right /></div>
          </div>
          <encyclopedia-list-item :item="itemSaved.item" display-type="Grid" @selected="navToItem(itemSaved.item.item_id)"></encyclopedia-list-item>
        </div>
      </div>
    </template>
  </async-save-modal>
</template>

<script>
import AsyncSaveModal from '@/components/AsyncSaveModal';
import EncyclopediaListItem from '@/components/EncyclopediaListItem';
import CustomInput from './CustomInput.vue';
import {ITEM_FIELDS} from '@/store/constants.js';
import {deepSet} from '@/mixins/utils.js';
import {BOverlay, BIconCheckCircle, BIconArrowRight} from 'bootstrap-vue';
import _ from "underscore";
import Vue from "vue";

export default {
  name: "NewItemModal",
  components: { AsyncSaveModal, EncyclopediaListItem, CustomInput, BOverlay, BIconCheckCircle, BIconArrowRight },
  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      itemType: undefined,
      newItem: this.getEmptyItem(),
      savingItem: false,
      uploadingImage: false,
      itemSaved: {},
      imageUploaded: {}
    }
  },
  watch: {
    itemType (newVal, oldVal) {
      if (newVal !== oldVal) {
        Vue.set(this.newItem, 'category_id', newVal.value);
      }
    }
  },
  computed: {
    fields () {
      return this.itemType ? ITEM_FIELDS[this.itemType.name] : [];
    },
    isValid () {
      return this.itemType && _.reduce(this.fields, (memo, f) => {
        let req = this.fieldIsRequired(f);
        let val = this.newItem[f.key];
        return (!req || val !== undefined && val !== null) && memo;
      }, true) && this.newItem.front_cover;
    }
  },
  methods: {
    getEmptyItem () {
      var newItemAllFields = {};
      _.each(ITEM_FIELDS, catFields => {
        _.each(catFields, f => {
          Vue.set(newItemAllFields, f.key, null);
        })
      })
      return newItemAllFields;
    },
    saveNewItem () {
      this.savingItem = true;
      let newItemToSave = {}
      _.each(this.newItem, (val, key) => {
        if (val === undefined || val === null) return;
        let field = _.findWhere(this.fields, {key: key});
        let dbKey = key;
        if (field && field.dbKey) dbKey = field.dbKey;
        deepSet(newItemToSave, dbKey, val);
      })
      return this.$store.dispatch("updates/addNewItem", newItemToSave)
        .then((newItem) => {
          this.savingItem = false;
          this.itemSaved = {
            success: true,
            item: newItem
          }
          this.uploadingImage = true;
          let f = new FormData();
          f.append("item_id", newItem.item_id);
          f.append("type_id", this.metadata.image_types["Front Box Art"].type);
          f.append("image", newItemToSave.front_cover);
          return this.$store.dispatch("updates/addImage", f)
            .then(() => {
              this.$store.dispatch('encyclopedia/getItemDetails', newItem.item_id)
                .then(details => {
                  Vue.set(this.itemSaved.item, "image", details.images["0"]?.at(0));
                })
                .finally(() => {
                  this.imageUploaded.success = true;
                })
            })
            .catch(e => {
              console.error("There was an issue uploading the image", e);
              this.imageUploaded = {
                success: false,
                msg: e.response.data
              }
              throw e;
            })
            .finally(() => {
              this.uploadingImage = false;
            });
        })
        .catch(e => {
          console.error("There was an error saving the item", e);
          if (_.isEmpty(this.itemSaved)) {
            // this means the error actually came from item saving, not image
            this.itemSaved.success = false;
            this.itemSaved.msg = e.response.data;
          }
          throw e;
        })
        .finally(() => {
          this.savingItem = false;
        })
    },
    fieldIsRequired (f) {
      return !!f.required ? (_.isFunction(f.required) ? f.required(f, this.newItem, this) : f.required) : f.required;
    },
    set (obj, key, val) {
      Vue.set(obj, key, val);
    },
    modalHidden () {
      this.itemSaved = {};
      this.imageUploaded = {};
      this.newItem = this.getEmptyItem();
    },
    navToItem (id) {
      let route = this.$router.resolve({ name: 'Details', params: { id: id } });
      window.open(route.href, "_blank");
    }
  }
}
</script>

<style lang="scss" scoped>
@import "@/assets/styles/_variables.scss";

.grid {
  grid-template-columns: min-content auto min-content auto;
  column-gap: 8px;
  row-gap: 16px;
  margin-top: 16px;
  align-items: center;
}

::v-deep textarea {
  background-color: var(--input-grey-bg);
  color: var(--input-grey-text);
}

.title-val, .front_cover-val {
  grid-column-start: span 3;
}

.req {
  padding: 0 3px;
}

@media (max-width: $mediumWidth) {
  .grid {
    grid-template-columns: min-content auto;
  }
  .title-val, .front_cover-val {
    grid-column-start: 2;
  }
}
</style>
