import _ from 'underscore';
import {CATEGORIES} from '@/store/constants.js';

export default {
  computed: {
    searchGroupMeta () {
      return {
        Platform: { groupId: 1, color: '#17a2b8', variant: 'info', queryKey: 'platforms', items: this.platformsList },
        Countries: { groupId: 6, color: '#343a40', variant: 'dark', queryKey: 'country', items: this.metadata.countries },
        Regions: { groupId: 5, color: '#343a40', variant: 'dark', queryKey: 'country', items: this.metadata.regions, queryValFunc: (vals) => { return _.reduce(vals, (list, obj) => { return _.union(list, obj.country_ids)}, []).join(); } },
        Genre: { groupId: 2, color: '#ffc107', variant: 'warning', queryKey: 'genre', items: this.genres },
        ReleaseType: { groupId: 7, color: '#28a745', variant: 'success', queryKey: 'release_type', items: this.metadata.release_types },
        Developer: { groupId: 3, color: '#6c757d', variant: 'secondary', queryKey: 'developer', items: this.developers },
        Publisher: { groupId: 4, color: '#6c757d', variant: 'secondary', queryKey: 'publisher', items: this.publishers }
      }
    },
    platformsList () {
      return this.$store.state.meta.platformsData ? this.$store.state.meta.platformsData.platforms : [];
    },
    platformGroupsList () {
      return this.$store.state.meta.platformsData ? this.$store.state.meta.platformsData.group_types : [];
    },
    platformGroupsMetadataList () {
      return this.$store.state.meta.platformsData ? this.$store.state.meta.platformsData.meta_types : [];
    },
    categories () {
      return CATEGORIES;
    },
    assignableCategories () {
      return _.sortBy(_.flatten(_.map(
        _.reject(this.categories, c => { return c.value === undefined }),
        c => { return c.children || c }
      )), 'value');
    },
    metadata () {
      return this.$store.state.meta.metadata;
    },
    developers () {
      return this.$store.state.meta.developers;
    },
    publishers () {
      return this.$store.state.meta.publishers;
    },
    genres () {
      return this.$store.state.meta.genres;
    },
    genresList () {
      return _.values(this.genres);
    },
    detailsLoaded () {
      return this.$store.state.meta.allMetaLoaded;
    }
  },
  methods: {
    parseObjectKeysForMatchingIds(obj, ids) {
      let picked = _.pick(obj, ids);
      return _.isEmpty(picked) ? undefined : picked;
    },
    parseListForMatchingId(list, id) {
      return _.findWhere(list, {id: id})
    },
    parseListForMatchingVal(list, matchObj) {
      return _.findWhere(list, matchObj);
    },
    parseListForMatchingIds(list, ids) {
      return _.filter(list, i => { return _.contains(ids, i.id); });
    },
    parseListForMultipleMatchingVal(list, matchObj) {
      return _.where(list, matchObj);
    },
    parseListForMatchingName(list, name) {
      return _.findWhere(list, {name: name});
    },
    parseListForMatchingField(list, field, val) {
      return _.findWhere(list, {[field]: val});
    },
    parseListForMatchingNameIgnoreCase(list, name) {
      return _.find(list, i => { return name.toLowerCase() === i.name.toLowerCase() });
    },
    parseListForMatchingNamesIgnoreCase(list, names) {
      return _.map(names, name => { return this.parseListForMatchingNameIgnoreCase(list, name) });
    },
    parseListForNamesString(list) {
      return list ? _.values(_.pluck(list, "name")).join(", ") : null;
    },
    parseIntsFromEditList(list) {
      // Remove "[" and "]" at beginning of string
      list = list.substring(1, list.length-1);
      var splitValues = list.split(",")
      
      var ints = []
      for (let i = 0; i < splitValues.length; i++) {
          var splitValue = splitValues[i]
          if (splitValue != "") {
              ints.push(parseInt(splitValue))
          }
      }
      return ints
    }
  }
}