<template>
  <div class="filters">
    <div class="inputs">
      <div style="text-align: center" v-if="!hideCategories">
        <b-dropdown :value="category" block no-flip variant="secondary" menu-class="w-100">
          <template slot="button-content">
            <icon-set class="icon unfilled" style="margin-right: 8px" :icon="category.icon" width="16px" height="16px"></icon-set>
            <span>{{category.label}}</span>
          </template>
          <b-dropdown-item v-for="cat in categories" :to="{ name: 'Browse', params: {category: cat.name}, query: $route.query }" :key="cat.value" class="opt">
            <icon-set class="icon unfilled" :icon="cat.icon" width="16px" height="16px"></icon-set>
            <span>{{cat.label}}</span>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="filter-search">
        <div class="header"><b-icon-funnel-fill style="margin-right: 8px"></b-icon-funnel-fill>Search Filters</div>
        <div class="search">
          <autocomplete :search="filterSearch" :get-result-value="item => {return item.name;}" ref="filterSearch" :debounce-time="200" @input.stop="null">
            <template slot-scope="obj">
              <div v-bind="obj.rootProps">
                <input
                  ref="input"
                  v-bind="obj.inputProps"
                  @input.stop="obj.inputListeners.input"
                  @keydown="obj.inputListeners.keydown"
                  @focus="obj.inputListeners.focus"
                  @blur="obj.inputListeners.blur"
                  v-on="$listeners"
                />
                <!--<div style="margin-top: 6px">
                  <a style="font-size: 0.8rem; color: #17CEFF" href @click.prevent="">Show All Options</a>
                </div>-->
                <div
                  ref="resultList"
                  v-bind="obj.resultListProps"
                  v-on="obj.resultListListeners"
                >
                  <div style="font-size: 0.85em">Search Results</div>
                  <hr style="margin: 5px 0; border-top: 1px solid white"/>
                  <div v-for="(results, cat) in filterResultsObj" :key="cat" class="category">
                    <div v-if="results.length > 0">
                      <div style="font-size: 0.75em; margin-bottom: 5px; color: #777">{{cat}}</div>
                      <ul>
                        <li v-for="result in results" :key="cat + result.id">
                          <pill class="filter-search-result" :text="result.name" :variant="result.variant" @click.native="filterAdded(cat, result)"></pill>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </autocomplete>
        </div>
      </div>
    </div>
    <div class="active">
      <div v-for="(fs, g) in selectedFilters" :key="g">
        <div class="group">{{g}}</div>
        <pill class="filter" v-for="(f, index) in fs" :key="'active' + g + f.id" :text="f.name" :variant="f.variant" isCloseable @close-clicked="removeFilter(g, index)"></pill>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'underscore';
import Autocomplete from '@trevoreyre/autocomplete-vue';
import Pill from "@/components/Pill";
import { BIconFunnelFill, BDropdown, BDropdownItem } from 'bootstrap-vue';
import IconSet from '@/components/IconSet';
import Vue from 'vue';

export default {
  name: 'SearchFilters',
  components: {
    Autocomplete,
    Pill,
    BIconFunnelFill,
    IconSet,
    BDropdown,
    BDropdownItem
  },
  props: {
    filters: { type: [String, Array] },
    category: { type: Object, required: true },
    hideCategories: { type: Boolean, default: false }
  },
  mounted () {
    if (this.filters) {
      if (_.isArray(this.filters)) {
        _.each(this.filters, (f) => { this.parseFilter(f); })
      } else {
        this.parseFilter(this.filters);
      }
      this.$emit('selected', this.selectedFilters);
    }
  },
  data () {
    return {
      filterResultsObj: {},
      selectedFilters: {}
    }
  },
  methods: {
    filterListBySearch (list, search) {
      return _.first(_.filter(list, (f) => { return f.name.toLowerCase().includes(search.toLowerCase()) }), 10);
    },
    addAttrsToFilterResults (list, attrsObj) {
      return _.map(list, (obj) => { _.extend(obj, attrsObj); return obj; });
    },
    filterSearch (input) {
      if (!input) return []
      return new Promise(resolve => {
        let results = []
        _.each(this.searchGroupMeta, (val, key) => {
          let filtered = this.addAttrsToFilterResults(this.filterListBySearch(val.items, input), _.omit(val, 'items'));
          results.push(filtered);
          this.filterResultsObj[key] = filtered;
        });
        resolve(results);
      })
    },
    addFilter (group, filter) {
      Vue.set(this.selectedFilters, group, _.union(this.selectedFilters[group] || {}, [filter]));
    },
    filterAdded (group, filter) {
      this.addFilter(group, filter);
      this.$refs.filterSearch.setValue('');
      this.$refs.filterSearch.core.handleBlur();
      this.$emit('selected', this.selectedFilters);
    },
    removeFilter (group, index) {
      this.selectedFilters[group].splice(index, 1);
      if (_.isEmpty(this.selectedFilters[group])) delete this.selectedFilters[group];
      this.$emit('selected', this.selectedFilters);
    },
    parseFilter (fString) {
      //TODO: error handle this
      let fStrArr = fString.split('-');
      let groupId = Number.parseInt(fStrArr[0]);
      let fId = Number.parseInt(fStrArr[1]);
      let groupName = _.findKey(this.searchGroupMeta, (val) => { return val.groupId === groupId; });
      let filter = _.extend(_.findWhere(this.searchGroupMeta[groupName].items, {id: fId}), _.omit(this.searchGroupMeta[groupName], 'items'));
      this.addFilter(groupName, filter);
    },
  }
}
</script>

<style lang="scss" scoped>

.filters {
  min-width: 200px;
  padding: 10px;
  font-size: 0.85rem;
  flex-grow: 1;
  .header {
    margin: 8px 0;
    font-weight: bold;
    font-size: 0.9rem;
  }
  .active {
    margin-top: 15px;
    border-top: 1px solid #4e5158;
    padding-top: 15px;
    .group {
      font-size: 0.8em;
      margin-bottom: 5px;
    }
    .filter {
      margin-bottom: 10px;
    }  
  }
  ::v-deep .b-dropdown {
    .dropdown-toggle{
      padding: 16px 12px;
      &:focus {
        box-shadow: none;
      }
      fill: white;
      border-color: var(--gameye-blue);
      background-color: #374C64;
    }
    .icon {
      margin-right: 8px;
    }
    > .dropdown-menu {
      background-color: var(--input-grey-bg);
    }
  }
}

.header, .group {
  color: #A9A9A9;
}

.autocomplete-result-list {
  background-color: var(--input-grey-bg);
  padding: 5px 10px;
  width: 125% !important;
  z-index: 10 !important;
  margin-top: 1px !important;
  border-radius: 3px !important;
  scrollbar-width: thin;
  &::-webkit-scrollbar {
    width: 10px;
    height: 10px;
    background-color: transparent;
  }
  &::-webkit-scrollbar-thumb {
    background: #686868;
  }
  .category {
    margin-bottom: 10px;
  }
  ul {
    padding-left: 0;
    li {
      list-style: none;
      margin-bottom: 3px;
    }
  }
}
</style>
