import _ from 'underscore';

export const GAMEYE_BLUE = "#007AFF";

export const AUTH_LEVELS = {
  ADDER: 500,
  MOD: 1000,
  ADMIN: 10000,
  OWNER: 100000
};

export const NAME_LENGTHS = {
  MIN_DISPLAY_NAME: 2,
  MAX_DISPLAY_NAME: 50,
  MIN_USER_NAME: 4,
  MAX_USER_NAME: 17
}

export const IMAGE_TYPES_BASE_URL_META_KEYS = {
  URL: "base_url",
  IMAGE: "base_image_url",
  PLATFORM: "base_platform_image_url",
  FLAG: "base_flag_image_url",
  RATINGS: "base_parental_ratings_image_url",
  PROFILE_PIC: "base_profile_image_url",
  DOCUMENTS: "base_documentation_url"
};

export const VGPC_GENRE_MAPPINGS = {
  fps: ["First-person shooter"],
  rpg: ["RPG"],
  "action & adventure": ["Action", "Adventure"],
  "action et adventure": ["Action", "Adventure"],
  "board & card": ["Board"],
  "soccer": ["Sports"],
  "basketball": ["Sports"],
  "third person shooter": ["Third-person shooter"],
  "survival horror": ["Horror"],
  "hockey": ["Sports"],
  "golf": ["Sports"],
  "football": ["Sports"],
  "sport": ["Sports"],
  "wrestling": ["Sports"],
  "fishing": ["Sports"],
  "baseball": ["Sports"],
  "horreur": ["Horror"],
  "extreme sports": ["Sports"],
  "skateboarding": ["Sports"],
  "shoot 'em up": ["Shoot 'em up"],
  "shoot'em up": ["Shoot 'em up"],
  "beat'em up": ["Beat 'em up"],
  "hack and slash": ["Hack 'n Slash"],
  "light gun" : ["Light Gun"],
  "visual novel" : ["Visual Novel"],
  "educational" : ["Education"],
  "dance" : ["Music"]
}

export const CATEGORIES = [
  {
    name: 'all',
    label: 'All',
    value: undefined,
    icon: 'logo-inverted'
  },
  {
    name: 'games',
    label: 'Games',
    value: 0,
    icon: 'game'
  },
  {
    name: 'systems',
    label: 'Systems',
    value: 1,
    icon: 'system'
  },
  {
    name: 'peripherals',
    label: 'Peripherals',
    value: '2,5',
    icon: 'peripheral',
    children: [
      {
        name: 'controllers',
        label: 'Controllers',
        value: 2,
        icon: 'peripheral'
      },
      {
        name: 'accessories',
        label: 'Accessories',
        value: 5,
        icon: 'peripheral'
      }
    ]
  },
  {
    name: 'toys',
    label: 'Toys to Life',
    value: 3,
    icon: 'toy'
  },
  {
    name: 'print',
    label: 'Print Media',
    value: 4,
    icon: 'print'
  }
];

export const QUEUE_TYPE_IDS = {
  TGDB: {
    EDIT: 0,
    NEW: 1,
    IMAGE: 2,
    DELETE: 4,
  },
  GAMEYE: {
    EDIT: 0,
    NEW: 1
  }
}

const itemFields = {
  common: [
    {
      label: "Name/Title",
      key: "title",
      required: true
    },
    {
      label: "Alt Titles",
      key: "alt_titles",
      type: "multi-text"
    },
    {
      label: "Platform",
      key: "platform_id",
      type: "select",
      noSort: true,
      func: pId => { return this.parseListForMatchingId(this.platformsList, pId) },
      options: (src) => { return src.platformsList },
      customComponent: "platform",
      getProps: pId => {return { platformId: pId }},
      required: true
    },
    {
      label: "Overview",
      key: "overview",
      type: "text-area"
    },
    {
      label: "Region",
      key: "country_id",
      func: cId => { return this.getFieldFromIdList(cId, this.metadata.countries, "abbr"); },
      type: "select",
      idListField: "abbr",
      options: (src) => { return _.values(src.metadata.countries) },
      required: true
    },
    {
      label: "Release Date",
      key: "release_date",
      func: d => { return d*1000 },
      type: "date"
    }
  ],
  games: [
    {
      label: "Release Type",
      key: "release_type",
      type: "select",
      func: rId => { return this.getFieldFromIdList(rId, this.metadata.release_types); },
      options: (src) => { return src.metadata.release_types; },
      required: true
    },
    {
      label: "Developer",
      key: "devs",
      func: dIds => { return this.getFieldFromIdList(dIds, this.developers); },
      type: "multiselect",
      options: (src) => { return _.values(src.developers) }
    },
    {
      label: "Genre",
      key: "genres",
      func: gIds => { return this.getFieldFromIdList(gIds, this.genres); },
      type: "multiselect",
      options: (src) => { return _.values(src.genres) },
      required: true
    },
    {
      label: "Publisher",
      key: "pubs",
      func: pIds => { return this.getFieldFromIdList(pIds, this.publishers); },
      type: "multiselect",
      options: (src) => { return _.values(src.publishers) }
    },
    {
      label: "Rating",
      key: "rating_id",
      dbKey: ["game_specific", "rating_id"],
      func: pId => { return this.getFieldFromIdList(pId, this.metadata.parental_ratings); },
      type: "select",
      options: (src) => { return src.metadata.parental_ratings },
      idListField: "name",
      getIconImg: (opt, src) => { return opt.file ? src.getImageURLByType(opt.file, 0, 'RATINGS') : undefined; },
      required: true
    },
    {
      label: "Players",
      key: "num_players",
      dbKey: ["game_specific", "num_players"],
      type: "number"
    },
    {
      label: "CoOp",
      key: "co_op",
      dbKey: ["game_specific", "co_op"],
      type: "boolean"
    }
  ],
  systems: [],
  controllers: [],
  accessories: [],
  toys: [],
  print: []
}

export const ITEM_FIELDS = _.mapObject(
  // select fields not 'common'
  _.omit(itemFields, 'common'),
  (fields) => {
    return [
      ...itemFields.common,
      ...fields
    ];
  }
);

export const ITEM_FIELDS_MAP = _.mapObject(
  ITEM_FIELDS,
  fields => {
    return _.indexBy(fields, 'key');
  }
);

export const ITEM_STATES = {
  GOOD: 1,
  DELETED: 2,
  NEEDS_REVIEW: 3
}

export default {
  GAMEYE_BLUE,
  AUTH_LEVELS,
  NAME_LENGTHS,
  IMAGE_TYPES_BASE_URL_META_KEYS,
  VGPC_GENRE_MAPPINGS,
  QUEUE_TYPE_IDS,
  ITEM_FIELDS,
  ITEM_FIELDS_MAP,
  ITEM_STATES
};