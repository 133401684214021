import { render, staticRenderFns } from "./EncyclopediaListItem.vue?vue&type=template&id=02b71357&scoped=true"
import script from "./EncyclopediaListItem.vue?vue&type=script&lang=js"
export * from "./EncyclopediaListItem.vue?vue&type=script&lang=js"
import style0 from "./EncyclopediaListItem.vue?vue&type=style&index=0&id=02b71357&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "02b71357",
  null
  
)

export default component.exports