import _ from "underscore";

export default {
  state: () => ({
    items: [],
    itemsTotalCount: undefined
  }),
  mutations: {
    setItems(state, items) {
      state.items = items || [];
    },
    addItems(state, items) {
      state.items.push.apply(state.items, items);
    },
    setItemsCount(state, totalCount) {
      state.itemsTotalCount = totalCount;
    },
    clearItems(state) {
      state.items.length = 0;
      state.items = [];
      state.itemsTotalCount = undefined;
    },
    clearItemsAfter(state, index) {
      state.items = _.first(state.items, index);
    }
  },
  actions: {
    clearItemsList(context) {
      context.commit('clearItems');
    },
    clearItemsAfter(context, index) {
      context.commit('clearItemsAfter', index);
    }
  }
}